$color1: #444;
$screen_width: 100%;

@font-face {
    font-family: "Roboto-Medium";
    src: url("assets/fonts/Roboto-Medium.ttf");
}

@font-face {
    font-family: "Roboto-Regular";
    src: url("assets/fonts/Roboto-Regular.ttf");
}

@font-face {
    font-family: "NotoSans-Regular";
    src: url("assets/fonts/NotoSans-Regular.ttf");
}

@mixin noselect {
    -webkit-user-select: none;
    /* Safari */
    -ms-user-select: none;
    /* IE 10 and IE 11 */
    user-select: none;
    /* Standard syntax */
}

@mixin default-button {
    box-sizing: border-box;
    border-radius: 10px;
    background-color: $color1;
    color: #fff;
    text-align: center;
    font-size: 16px;
    padding: .7em 1.5em;
    cursor: pointer;
}

@mixin window-screen-container {
    position: fixed;
    display: grid;
    justify-content: center;
    align-items: center;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: #0006;
    z-index: 500;
}
@mixin window-screen-content-block {
    width: 250px;
    height: 300px;
    box-sizing: border-box;
    border-radius: 10px;
    padding: 1em;
    background-color: #f1f1f1;
}

a {
    text-decoration: none;
    color: $color1;

    &:visited {
        color: $color1;
    }
}

ul,
li,
body {
    margin: 0;
    padding: 0;
    font-family: "NotoSans-Regular";
}

.block-pc{
    display: grid;
    justify-content: center;
    justify-items: center;
    align-items: center;
    align-content: center;
    text-align: center;
    width: 100%;
    height: 100vh;
    padding: 1em;
    img{
        width: 100px;
    }
}

.global-loading {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    svg {
        animation: .5s infinite loading linear;
    }
}

.error{
    color:rgb(218, 63, 63);
}

.prompt {
    color: #ccc;
    margin: -14px 0 0 0;
}

body {
    display: flex;
    justify-content: center;
    color: $color1;

    .global-container {
        position: relative;
        overflow: hidden;
        overflow-y: auto;
        height: 100vh;
        width: 100vw;
        background-color: #fff;
        box-sizing: border-box;

        &::-webkit-scrollbar {
            width: 0px;
        }

        &::-webkit-scrollbar-track {
            background-color: #e4e4e4;
            border-radius: 100px;
        }

        &::-webkit-scrollbar-thumb {
            background-color: #aaa;
            border-radius: 100px;
        }

        .save_message {
            @include default-button();
            background-color: #4c4;
            cursor: auto;
        }

        .header {
            display: flex;
            align-items: center;
            gap: 1em;
            padding: 1em 2em;
            box-sizing: border-box;
            width: 100%;
            color: #fff;
            background-color: $color1;
            
            .shop-select{
                cursor: pointer;
            }

            .menu-toggle {
                position: relative;
                width: 30px;
                height: 20px;
                cursor: pointer;

                &.active {
                    .line:nth-child(1) {
                        transform: rotate(45deg);
                        top: 50%
                    }

                    .line:nth-child(2) {
                        opacity: 0;
                    }

                    .line:nth-child(3) {
                        transform: rotate(-45deg);
                        top: 50%
                    }
                }

                .line {
                    position: absolute;
                    background-color: #fff;
                    width: 100%;
                    height: 2px;
                    transition: .1s;

                    &:nth-child(1) {
                        top: 0px
                    }

                    &:nth-child(2) {
                        top: 50%
                    }

                    &:nth-child(3) {
                        top: 100%
                    }
                }
            }

            .logo {
                @include noselect();
                font-size: 20px;
                flex: 1;
                .shop-select{
                    display: flex;
                    align-items: center;
                    gap: .3em;
                    p{
                        color: #e1e1e1;
                        margin: 0;
                        font-size: 14px;
                    }
                }
                a {
                    color: #fff
                }
            }


            .basket {
                position: relative;

                svg {}

                .basket-amount {
                    position: absolute;
                    bottom: 0;
                    right: 0;
                    display: flex;
                    background-color: #ff9720;
                    border-radius: 100%;
                    font-size: 12px;
                    font-weight: bold;
                    text-align: center;
                    justify-content: center;
                    align-items: center;
                    width: 20px;
                    height: 20px;
                    color: #fff;
                }
            }

            .user_but {
                cursor: pointer;
            }
        }

        .active-menu {
            position: absolute;
            top: 72px;
            left: -310px;
            height: 200%;
            width: 300px;
            background-color: #ffffff;
            z-index: 10;
            transition: .1s;
            box-shadow: 3px 0px 10px -10px #000;

            &.active {
                left: 0;
            }

            .catalog-but {
                padding: 1em;
            }

            ul {
                list-style: none;

                li {
                    @include noselect();
                    display: flex;
                    align-items: center;
                    justify-content: space-between;
                    cursor: pointer;
                    border-bottom: 1px solid #ddd;

                    a {
                        width: 100%;
                        padding: 1em;
                    }


                    svg {
                        transition: .2s;
                    }

                    svg.active {
                        transform: rotate(180deg);
                    }
                }

                .catalog {
                    @include noselect();
                    height: 0px;
                    overflow: hidden;
                    padding-left: 2em;
                    transition: .1s;

                }
            }

        }

        .back-block {
            display: flex;
            align-items: center;
            padding: 0 .5em;
            cursor: pointer;

            p {
                padding-left: .5em;
                font-size: 16px;
            }
        }


        .blocks {
            width: $screen_width;
            padding: .5em;
            box-sizing: border-box;
            &.ctg-page-blocks {
                display: grid;
                flex-wrap: wrap;
                justify-content: center;
                box-sizing: border-box;
                justify-items: center;
                gap: 1em;
                grid-template-columns: 1fr 1fr;

                .swiper-wrapper{
                    .block {
                        display: grid;
                        box-sizing: border-box;
    
                        a {
                            display: grid;
                            justify-items: center;
                            justify-content: center;
                            box-sizing: border-box;
    
                            img {
                                display: flex;
                                box-sizing: border-box;
                                width: 230px;
                            }
                        }
                    }
                }
            }

            .block {
                display: grid;
                justify-items: center;
                text-align: center;
                box-sizing: border-box;

                p {
                    margin: 0;
                }

                .price {
                    font-size: 18px;
                    font-weight: bold;
                    padding: .5em;
                    box-sizing: border-box;
                }

                .discription {}

                img {
                    border-radius: 10px;
                    object-fit: cover;
                    width: calc(50vw - 34px);
                    height: 220px;
                }
            }
        }

        main {
            display: grid;
            box-sizing: border-box;
            padding-bottom: 4em;

            .empty-text-home{
                display: grid;
                height: 100%;
                align-content: center;
                justify-items: center;
                text-align: center;
                font-size: 24px;
                img{
                    width: 50px;
                }
            }

            .category {
                padding-top: 1em;
                width: 100%;
                height: max-content;
                max-height: 400px;
                display: grid;
                justify-items: center;
                overflow: hidden;
                
                .swiper-wrapper{
                    height: fit-content;
                }

                &-heading {
                    @include noselect();
                    display: flex;
                    padding: 1em;
                    justify-content: space-between;
                    align-items: center;
                    font-size: 22px;
                    width: 100%;
                    box-sizing: border-box;
                    font-weight: bold;

                    .toall {
                        display: flex;
                        gap: .5em;
                        font-weight: 100;
                        font-size: 14px;
                        justify-self: end;
                        cursor: pointer;
                        align-items: center;
                    }
                }

            }
        }

        .thank-page{
            display: grid;
            height: calc(100vh - 100px);
            align-items: center;
            align-content: center;
            justify-content: center;
            justify-items: center;
            img{
                width: 50px;
                height: 50px;
            }
            .back-to-main{
                @include default-button();
                width: fit-content;
            }
            .loading{
                animation: .5s infinite loading linear;
            }
        }

        .shops-page-container{
            padding-bottom: 2em;
            .shops-content{
                display: grid;
                justify-content: center;
                gap: 1em;

                h1{
                    padding: 0 1em;
                }
                
                .copy-notification{
                    position: absolute;
                    display: grid;
                    justify-content: center;
                    align-content: center;
                    font-size: 12px;
                    bottom: 15%;
                    left: calc(50% - 60px);
                    background-color: #444c;
                    opacity: 0;
                    transition: .3s;
                    color: #fff;
                    width: 120px;
                    height: 40px;
                    border-radius: 10px;
                    z-index: 999;
                    &.active{
                        opacity: 1;
                    }
                }
                .admin-shops-item{
                    position: relative;
                    display: grid;
                    background-color: #f1f1f1;
                    border-radius: 10px;
                    box-sizing: border-box;
                    padding: .5em 1em;
                    margin: 0 1em;
                    justify-content: flex-start;
                    .admin-shops-item-wrapper{
                        display: flex;
                        align-items: center;
                        gap: .5em;
                        .item-name{
                            display: flex;
                            align-items: center;
                            gap: .5em;
                            p{
                                font-size: 14px;
                                font-weight: bold;
                            }
                            .item-edit-button{
                                height: fit-content;
                                cursor: pointer;
                                svg{
                                    display: block;
                                }
                            }
                        }
                        .item-status{
                            cursor: pointer;
                        }
                    }
                    .item-link{
                        margin-left: auto;
                        display: flex;
                        gap: .5em;
                        align-items: center;
                        cursor: pointer;
                        background-color: #ffffff;
                        padding: .5em;
                        border-radius: 10px;
                        p{
                            overflow: hidden;
                            text-overflow: ellipsis;
                            white-space: nowrap;
                            margin: 0;
                            font-size: 14px;
                            font-style: italic;
                        }
                    }
                }
                .admin-shops-new-shop-button{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                    background-color: #444444;
                    border-radius: 10px;
                    padding: .5em 1em;
                    margin: 0 1em;
                    cursor: pointer;
                }
            }
        }

        .product-page-container {
            padding-bottom: 2em;

            .img-album {
                background-color: #8881;

                .swiper-pagination-bullet-active {
                    background-color: $color1;
                }

                .swiper-slide {
                    display: flex;
                    justify-content: center;
                    align-items: center;
                    height: 400px;
                }

                img {
                    max-width: 100%;
                    max-height: 400px;
                }
            }

            .content-block {
                padding: 0 .5em;

                .name-product {
                    font-size: 22px;
                    font-weight: bold;
                }

                .price-product {
                    font-size: 26px;
                    font-weight: bold;
                }

                .to-basket-button {
                    @include noselect();
                    @include default-button();
                    width: 100%;
                    border: 3px solid $color1;
                    transition: .3s;

                    &.in-basket {
                        display: grid;
                        background-color: #4440;
                        color: $color1;
                        font-weight: bold;
                    }
                }
            }
        }

        .basket-container {
            position: relative;
            width: 100%;
            height: calc(100vh - 75px);
            box-sizing: border-box;

            .content-block {
                width: 100%;
                padding-bottom: 50vh;

                .basket-item {
                    display: flex;
                    gap: 1em;
                    padding: 1em;

                    img {
                        width: 100px;
                        height: 100px;
                        object-fit: cover;
                    }

                    .item-info {
                        display: grid;
                        width: 100%;
                        flex-wrap: wrap;
                        grid-template-columns: 3fr 1fr;

                        .item-name {
                            flex: 90%;
                        }

                        .item-delete {
                            display: flex;
                            flex: 10%;
                            justify-content: flex-end;
                            cursor: pointer;
                        }

                        .item-amount-control {
                            @include noselect();
                            display: flex;
                            flex: 30%;
                            align-items: center;
                            gap: 1em;

                            .control-button {
                                padding: .2em;
                                width: 20px;
                                height: 20px;
                                text-align: center;
                                font-weight: bold;
                                border-radius: 100%;
                                background-color: $color1;
                                cursor: pointer;
                            }

                            .item-amount {
                                font-size: 20px;
                            }
                        }

                        .item-price {
                            display: grid;
                            flex: 70%;
                            align-items: center;
                            justify-content: flex-end;

                            p {
                                margin: 0;
                                font-size: 24px;
                                font-weight: bold;
                            }
                        }
                    }
                }

                .footer-block {
                    height: 170px;
                    position: fixed;
                    bottom: 1em;
                    width: 100%;
                    padding: 1em;
                    box-sizing: border-box;
                    background-color: #fff;

                    .result-block {
                        padding: .5em;
                        text-align: right;

                        p {
                            margin: 0
                        }

                        &-heading {}

                        &-sum {
                            font-size: 34px;
                            font-weight: bold;
                        }
                    }

                    .checkout-but {
                        @include default-button();
                        width: $screen_width;
                        display: flex;
                        justify-content: center;
                        color: #fff;
                    }
                }
            }

            .empty-basket {
                display: grid;
                justify-content: center;
                justify-items: center;
                align-items: start;
                align-content: center;
                height: calc(100% - 120px);

                &-text {
                    font-size: 24px;
                }

                &-button {
                    @include default-button();
                    width: 100%;
                }
            }
        }

        .dialog-select-shop-container{
            position: fixed;
            top: 0;
            left: 0;
            height: 100%;
            width: 100%;
            background-color: #0005;
            z-index: 100;
            .dialog-select-shop-block{
                position: absolute;
                top: 50%;
                left: 50%;
                padding: 1em;
                border-radius: .5em;
                transform: translate(-50%, -50%);
                width: 350px;
                height: fit-content;
                background-color: #fff;
                .dialog-select-shop-header{
                    font-size: 22px;
                    margin: 0;
                    margin-bottom: .5em;
                }
                .dialog-select-shop-body{
                    display: grid;
                    .dialog-select-shop-body-item{
                        display: flex;
                        gap: .5em;
                        align-items: center;
                        cursor: pointer;
                    }
                }
            }
        }

        .dialog-start-select-shop-container{
            position: absolute;
            top: 0;
            left: 0;
            right: 0;
            bottom: 0;
            background-color: #fff;
            z-index: 999;
            .dialog-start-select-shop-block{
                position: absolute;
                top: 50%;
                left: 50%;
                padding: 1em;
                border-radius: .5em;
                transform: translate(-50%, -50%);
                width: 90%;
                .dialog-start-select-shop-header{
                    text-align: center;
                    p{
                        margin: 0;
                    }
                    .heading{
                        font-size: 32px;
                    }
                    .subheading{
                        margin-top: 1em;
                        font-size: 18px;
                    }
                }
                .dialog-start-select-shop-body{
                    display: grid;
                    margin-top: 2em;
                    gap: 1em;
                    &-item{
                        display: flex;
                        padding: 1em 1em;
                        border-radius: 10px;
                        cursor: pointer;
                        gap: 1em;
                        align-items: center;
                        background-color: #f1f1f1;
                        justify-content: space-between;
                        p{
                            margin: 0;
                        }
                        svg{

                        }
                    }
                }
            }
        }

        .build-order-container {
            padding-bottom: 2em;

            .deliver-block {
                display: flex;
                padding: 1em 1em 0 1em;
                justify-content: center;
                gap: 1em;

                .select-item {
                    padding: 1.5em;
                    flex: 50%;
                    cursor: pointer;
                    background-color: #ccc8;
                    border-radius: 10px;
                    color: $color1;
                    transition: .1s;

                    p {
                        margin: 0;
                        font-weight: bold;
                        text-align: center;
                        font-size: 20px;
                    }

                    &.active {
                        color: #fff;
                        background-color: $color1;
                    }
                }
            }

            .order-info-block {
                padding: 0 1em;

                .date-order {
                    width: 100%;
                    box-sizing: border-box;

                    input {
                        font-family: "Roboto-Regular";
                        font-size: 16px;
                    }
                }

                .time-order {
                    margin-top: 1em;

                    .MuiSelect-select {
                        font-family: "Roboto-Regular";
                        font-size: 16px;
                    }
                }

                .address-order {
                    margin-top: 1em;
                }

                .input-type {

                    label {
                        font-family: "Roboto-Regular";
                    }
                }



                p.heading {
                    margin: 0;
                    margin-top: .5em;
                    padding: .3em 0;
                    font-size: 16px;

                    &.long {
                        margin-top: .5em;
                        font-size: 24px;
                    }
                }

                .number-order {
                    width: 100%;
                    margin-top: 1em;

                    input {
                        width: 100%;
                        height: 60px;
                        font-family: "Roboto-Regular";
                        font-size: 18px;
                    }
                }

                .checkbox-container {
                    margin-top: 1em;

                    .checkbox-label {
                        span {
                            font-size: 16px;
                        }
                    }

                    .checkbox-hide-block {
                        background-color: #ccc3;
                        height: 0px;
                        overflow: hidden;
                        transition: .2s;

                        &.open {
                            padding: 1em
                        }

                        &.hide-block-other {
                            &.open {
                                height: 155px
                            }
                        }

                        &.hide-block-postcard {
                            &.open {
                                height: 55px
                            }
                        }
                    }

                    .input-type {
                        margin-top: 0;
                    }
                }


            }

            .points-info-block{
                margin: 0 1em;
                border-radius: 10px;
                color: #444;
                h3{
                    text-align: center;
                }
                .points-select-act{
                    display: flex;
                    gap: .5em;
                    background-color: #ffffff;
                    border-radius: 15px;
                    .points-select-button{
                        padding: .5em;
                        text-align: center;
                        flex: 50%;
                        cursor: pointer;
                        background-color: #ccc;
                        border-radius: 10px;
                        box-sizing: border-box;
                        color: $color1;
                        transition: .1s;
                        &.active {
                            color: #fff;
                            background-color: $color1;
                        }
                    }
                }
                .points-block-header{
                    display: flex;
                    gap: .5em;
                    align-items: center;
                    .points-button{
                        cursor: pointer;
                    }
                }
            }

            .finish-info-block{
                margin: 1em 1em 0 1em;
                .line{
                    display: flex;
                    justify-content: space-between;
                    &.points_green{color: #4c4}
                    &.points_red{color: #c44}
                }
                p{
                    margin: 0;
                }
            }

            .build-order-but {
                position: relative;
                @include default-button();
                margin: 1.5em 1em 1em 1em;
                .loader{
                    padding: 11.2px;
                }
            }
        }

        .user-info-container {
            display: grid;
            gap: 1em;
            padding: 1em;
            color: $color1;


            .save-but {
                @include default-button();
                position: relative;
            }

            .heading-block {
                display: grid;
                margin: 1em 0;
                gap: 1em;
                grid-template-columns: 1fr 1fr;
                align-items: center;
                justify-content: space-between;
                h1, h3{
                    margin: 0
                }
                .user-info-points-block{
                    grid-column: 1/3;
                    display: flex;
                    gap: .5em;
                    .info-button{
                        cursor: pointer;
                    }
                }

                .admin-panel-button {
                    @include default-button();

                }
            }

            .phone-number {
                width: 100%;

                input {
                    width: 100%;
                    padding: 1.5em 3em;
                    font-size: 16px;
                }
            }

        }
        .window-screen-info-points{
            @include window-screen-container();
            .window-screen-block{
                @include window-screen-content-block();
                .header-block{
                    display: flex;
                    justify-content: space-between;
                    .info-button{
                        cursor: pointer;
                    }
                }
            }
        }

        .add-new-shop-container, .edit-new-shop-container{
            @include window-screen-container();
            .add-new-shop-block, .edit-new-shop-block{
                @include window-screen-content-block();
                width: 400px;
                height: 200px;
                display: grid;
                gap: 1em;
                .add-new-shop-header, .edit-new-shop-header{
                    font-size: 24px;
                    margin: 0;
                }
                .add-new-shop-input, .edit-new-shop-input{

                }
                .add-new-shop-button, .edit-new-shop-button{
                    @include default-button();
                }
            }
        }


        .ctg-page-container {
            padding-bottom: 2em;

            .heading {
                padding: 1em;
            }
        }

        .admin-panel-container {
            display: grid;
            height: calc(100% - 73px);
            align-content: start;
            align-items: start;

            h1 {
                text-align: center;
                margin: 0;
            }

            .admin-panel-header{
                margin-top: 2em;
                padding: 2em;
                .shop-select{
                    display: flex;
                    align-items: center;
                    justify-content: center;
                }
            }

            .main-block {
                display: grid;
                grid-template-columns: 1fr 1fr;
                gap: 1em;
                width: 100%;
                padding: 1em;
                box-sizing: border-box;

                .block {
                    height: 80px;
                    background-color: $color1;
                    color: #fff;
                    display: flex;
                    flex: 1;
                    align-items: center;
                    justify-content: center;
                    font-size: 26px;
                    cursor: pointer;
                    border-radius: 10px;
                }

            }
        }

        .admin-block-container {
            .heading {
                text-align: center;
            }


            .categories-block {
                display: flex;
                gap: .5em;
                flex-wrap: wrap;
                align-items: center;

                .selection-block {
                    display: flex;
                    gap: .5em;
                    align-items: center;

                    .selection-input {
                        height: 33px;
                    }

                    .MuiSelect-select {
                        padding-top: 0 !important;
                        padding-bottom: 0 !important;
                    }

                    .add-ctg-block {
                        display: flex;
                        gap: .5em;
                    }
                }

                .category-item {
                    display: flex;
                    gap: .5em;
                    padding: .3em 1em;
                    border-radius: 10px;
                    background-color: #ccc;
                    align-items: center;

                    p {
                        margin: 0;
                    }

                    svg {
                        cursor: pointer
                    }

                    &.but {
                        cursor: pointer
                    }
                }
            }

            &.categories-page-container {
                display: grid;
                padding-bottom: 1em;

                .categories-list {
                    display: grid;
                    gap: 1em;
                    padding: 1em;

                    .categories-item {
                        border-radius: 10px;
                        align-items: center;
                        box-sizing: border-box;
                        justify-content: space-between;
                        display: flex;
                        width: 100%;
                        gap: 1em;
                        padding: .5em 1em;
                        background-color: $color1;
                        color: #fff;

                        input {
                            color: #fff !important;
                            font-family: "Roboto-Regular";
                        }

                        .item-control {
                            display: flex;
                            gap: 1em;
                        }

                        .item-name {
                            flex-grow: 1;
                        }

                        svg {
                            cursor: pointer;
                        }
                    }

                    .categories-control {
                        justify-self: center;
                        width: 100%;
                        height: 50px;
                        background-color: #777;
                        border-radius: 10px;
                        cursor: pointer;
                    }

                    .add-block {
                        display: flex;
                        align-items: center;
                        gap: 1em;
                        flex-grow: 1;

                        .name-ctg {
                            flex-grow: 1;
                        }

                        svg {
                            width: 50px;
                            height: 50px;
                            cursor: pointer;
                        }

                        .add-ctg {
                            background-color: $color1;
                            border-radius: 10px;
                        }
                    }
                }
            }

            &.products-page-container {

                .products-control {
                    margin: 0 1em;

                    .heading {}

                    display: flex;
                    justify-content: space-between;
                    align-items: center;

                    .add_but {
                        @include default-button();
                    }
                }

                .products-list {
                    display: grid;
                    align-content: start;
                    box-sizing: border-box;
                    gap: 1em;
                    margin: 1em;

                    .product-item {
                        width: 100%;
                        padding: 1em;
                        box-sizing: border-box;
                        border-radius: 10px;
                        background-color: #eee;

                        &-name,
                        &-price {
                            display: flex;
                            gap: 1em;
                            margin: .5em 0;
                            justify-content: space-between;
                            align-items: center;

                            h3,
                            p {
                                margin: 0;
                            }

                            .field {
                                width: 100%;
                                height: 40px;

                                .MuiInputBase-input {
                                    padding-top: .5em;
                                    padding-bottom: .5em;
                                }
                            }

                            .admin-but {
                                padding: .2em;
                                min-width: 20px;
                                max-width: 20px;
                                width: 20px;
                                height: 20px;
                                cursor: pointer;
                            }
                        }


                        .album {
                            width: 250px;
                            overflow-x: auto;
                            display: flex;
                            align-items: flex-end;
                            box-sizing: border-box;
                            padding: .5em;
                            gap: .5em;

                            &-item {
                                width: 80px;
                                min-width: 80px;
                                height: 80px;
                                display: flex;

                                &.select-item {
                                    position: relative;

                                    &::after {
                                        content: "";
                                        position: absolute;
                                        top: 0;
                                        bottom: 0;
                                        left: 0;
                                        right: 0;
                                        background-color: #ff06;
                                        border-radius: 4px;
                                    }
                                }

                                img {
                                    @include noselect();
                                    width: 80px;
                                    min-width: 80px;
                                    height: 80px;
                                    object-fit: cover;
                                    border-radius: 4px;
                                    box-shadow: 2px 2px 4px -1px #666;

                                    &:nth-child(1) {
                                        width: 80px;
                                        min-width: 80px;
                                        height: 80px;
                                    }
                                }

                            }

                        }

                        .img-control {
                            display: flex;
                            margin-top: 1em;
                            justify-content: space-between;

                            .control-item {
                                display: flex;
                                padding: .2em;
                                width: 25px;
                                height: 25px;
                                display: flex;
                                border-radius: 4px;
                                background-color: #aaa;
                                cursor: pointer;
                                justify-items: center;
                                align-items: center;

                                label {
                                    display: flex;
                                }

                                svg {
                                    width: 100%;
                                    height: 100%;
                                }

                                &.file-upload-but {
                                    input {
                                        display: none
                                    }
                                }

                                &.delete-img-toggle {
                                    background-color: rgb(218, 63, 63);
                                }

                            }

                            .control-block {
                                display: flex;
                                gap: .5em;
                            }
                        }

                        .categories-block-heading {
                            margin: 1em 0 .5em 0;
                        }

                        .delete-but {
                            @include default-button();
                            margin-top: 1em;
                            width: fit-content;
                            background-color: #cc2727;
                        }

                    }
                }
            }

            &.add-product-page-container {
                .block-inputs {
                    display: grid;
                    gap: 1em;
                    align-items: start;
                    padding-bottom: 1em;

                    .categories-block-heading {
                        margin: 1em 0 0 0;
                    }

                    .input {
                        width: 100%;
                    }

                    .load-images-container {
                        .images-block {
                            display: flex;
                            flex-wrap: wrap;
                            gap: 1em;
                            margin: .5em;

                            .image-item {
                                img {
                                    width: 80px;
                                    height: 80px;
                                    object-fit: cover;
                                }
                            }
                        }
                    }

                    .add-product-but {
                        @include default-button();
                        margin: 1em 0
                    }
                }

                .load-images-but {
                    @include default-button();
                    display: grid;

                    input {
                        display: none;
                    }
                }
            }

            &.contacts-page-container {
                .contacts-content {
                    display: grid;
                    padding: 0 1em 2em 1em;
                    gap: 1em;

                    .but-send {
                        @include default-button();
                        position: relative;
                        height: 45px;
                    }
                }
            }

            &.orders-page-container {
                .order-content {
                    h1{
                        text-align: center;
                    }
                    .order-item {

                        &.close-item {
                            opacity: .6;

                            .close-tag {
                                margin: 0;
                                color: #cc2727;
                            }
                        }

                        .heading-item {
                            display: flex;
                            align-items: center;
                            gap: .5em;

                        }

                        .s1 {
                            color: #944
                        }

                        .s2 {
                            color: #994
                        }
                        background-color: #ccc;
                        padding: 1em;
                        margin: 1em;
                        border-radius: 10px;

                        .key {
                            color: #000;
                        }

                        p {
                            margin: .5em;
                        }

                        .order-completed-but {
                            @include default-button();
                            position: relative;
                            margin-top: 1em;

                            &.loading-but {
                                padding: 1.4em;
                            }
                        }
                    }
                }
            }

        }

        .contacts-container {
            h1 {
                text-align: center;
            }

            .map_widget {}

            .tg-link {
                display: flex;
                gap: .5em;
                align-items: center;
                width: fit-content;
            }
            .contact-content{
                padding: 0 1em;
            }
        }
    }
}

@media (min-device-width: 480px) {

    .global-container {
        width: 480px !important;

        .blocks {
            .swiper-slide.block {
                img {
                    width: 230px;
                }
            }
        }

        .footer-block {
            width: 480px !important;
        }
    }
}

@media (max-device-width: 480px) and (min-device-width: 400px) {
    .global-container {
        width: 100vw;

        .blocks {
            .swiper-slide.block {
                img {
                    height: 180px !important;
                }
            }
        }

        .admin-panel-container {
            h1 {
                font-size: 24px;
                padding: 1em .5em .5em .5em !important;
            }

            .main-block {
                .block {
                    height: 80px !important;
                    font-size: 22px !important;
                }
            }
        }

        .ctg-page-blocks.blocks {
            .block {
                img {
                    width: 40vw !important;
                    height: 180px !important;
                }
            }
        }
    }
}

@media (max-device-width: 400px) and (min-device-width: 330px) {
    .global-container {
        width: 100vw;

        .header {
            .logo {
                font-size: 16px !important;
            }

            svg {
                width: 25px !important;
                height: 25px !important;
            }

            .menu-toggle {
                width: 22px !important;
                height: 14px !important;
            }

            .basket-amount {
                width: 15px !important;
                height: 15px !important;
            }
        }

        .products-page-container {
            .products-control {
                h1 {
                    font-size: 22px !important;
                }
            }
        }

        .admin-panel-container {
            h1 {
                font-size: 24px;
                padding: 1em .5em .5em .5em !important;
            }

            .main-block {
                .block {
                    height: 80px !important;
                    font-size: 22px !important;
                }
            }
        }

        .user-info-container {
            .heading-block {
                h1 {
                    font-size: 22px !important
                }
            }
        }

        .product-page-container {
            .content-block {
                .name-product {
                    font-size: 20px !important
                }

                .price-product {
                    font-size: 22px !important
                }
            }
        }

        .blocks {
            .swiper-slide.block {
                p {
                    font-size: 16px !important;
                }

                img {
                    width: 55vw !important;
                    height: 180px !important;
                }
            }
        }

        .category-heading {
            font-size: 18px !important;

            .toall {
                font-size: 14px !important;
            }
        }

        .ctg-page-container {
            .heading {
                h1 {
                    margin: .5em;
                    font-size: 28px;
                }
            }

            .ctg-page-blocks.blocks {
                column-gap: 0 !important;

                .block {
                    p {
                        font-size: 16px !important;
                    }

                    img {
                        width: 42vw !important;
                        height: 180px !important;
                    }
                }
            }
        }
    }
}

@media (max-device-width: 330px) and (min-device-width: 276px) {
    .global-container {
        width: 100vw;

        .header {
            .logo {
                font-size: 14px !important;
            }

            svg {
                width: 25px !important;
                height: 25px !important;
            }

            .menu-toggle {
                width: 22px !important;
                height: 14px !important;
            }

            .basket-amount {
                width: 15px !important;
                height: 15px !important;
            }
        }


        .products-page-container {
            .products-control {
                h1 {
                    font-size: 22px !important;
                }
            }
        }


        .admin-panel-container {
            h1 {
                font-size: 24px;
                padding: 1em .5em .5em .5em !important;
            }

            .main-block {
                .block {
                    height: 80px !important;
                    font-size: 20px !important;
                }
            }
        }

        .user-info-container {
            .heading-block {
                h1 {
                    font-size: 22px !important
                }
            }
        }

        .product-page-container {
            .content-block {
                .name-product {
                    font-size: 16px !important
                }

                .price-product {
                    font-size: 20px !important
                }
            }
        }

        .blocks {
            .swiper-slide.block {
                p {
                    font-size: 14px !important;
                }

                img {
                    width: 55vw !important;
                    height: 160px !important;
                }
            }
        }

        .img-album {
            .swiper-slide {
                height: 350px !important;
            }
        }

        .category-heading {
            font-size: 18px !important;

            .toall {
                font-size: 12px !important;
            }
        }

        .ctg-page-container {
            .heading {
                h1 {
                    margin: .3em;
                    font-size: 24px;
                }
            }

            .ctg-page-blocks.blocks {
                column-gap: 0 !important;

                .block {
                    p {
                        font-size: 14px !important;
                    }

                    img {
                        width: 45vw !important;
                        height: 150px !important;
                    }
                }
            }
        }
    }
}

@media (max-device-width: 276px) {
    .global-container {
        width: 100vw;

        .header {
            .logo {
                font-size: 12px !important;
            }

            .menu-toggle {
                width: 22px !important;
                height: 14px !important;
            }

            .basket-amount {
                width: 15px !important;
                height: 15px !important;
            }
        }

        .products-page-container {
            .products-control {
                h1 {
                    font-size: 22px !important;
                }
            }
        }


        .admin-panel-container {
            h1 {
                font-size: 24px;
                padding: 1em .5em .5em .5em !important;
            }

            .main-block {
                .block {
                    height: 80px !important;
                    font-size: 18px !important;
                }
            }
        }


        .user-info-container {
            .heading-block {
                h1 {
                    font-size: 22px !important
                }
            }
        }

        .product-page-container {
            .content-block {
                .name-product {
                    font-size: 16px !important
                }

                .price-product {
                    font-size: 20px !important
                }
            }
        }

        .img-album {
            .swiper-slide {
                height: 300px !important;
            }
        }

        .blocks {
            .swiper-slide.block {
                p {
                    font-size: 14px !important;
                }

                img {
                    width: 55vw !important;
                    height: 140px !important;
                }
            }
        }

        .category-heading {
            font-size: 18px !important;

            .toall {
                font-size: 12px !important;
            }
        }

        .ctg-page-container {
            .heading {
                h1 {
                    margin: .3em;
                    font-size: 24px;
                }
            }

            .ctg-page-blocks.blocks {
                column-gap: 0 !important;

                .block {
                    p {
                        font-size: 14px !important;
                    }

                    img {
                        width: 40vw !important;
                        height: 120px !important;
                    }
                }
            }
        }
    }
}


@keyframes loading {
    from {
        transform: rotate(0deg);
    }

    to {
        transform: rotate(360deg);
    }
}